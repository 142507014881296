label{
    color: white;
}

fieldset{
    display: flex;
    flex-direction: column;
    padding: 2rem 0.5rem;
    margin: auto;
    max-width: 600px;
}

.btn {
    display: block;
  margin-left: auto;
  margin-right: auto;
    max-width: 600px;
}


input, textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    color: white;
}