
  
  .skills {
    margin: auto;
    max-width: 600px;
    color: aliceblue;
    vertical-align: top;
    align-items: center;
    justify-content: center;
  }
  
  .skills h1 {
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .resp-table-row {
    display: table-row;
  }
  
  .table-body-cell {
    display: table-cell;
    padding: 15px;
  }
  
  #resp-table {
    width: 100%;
    display: table;
    font-size: 1rem;
    margin-left: 30px;
    margin-right: 30px;
    border-spacing: 0 15px;
  }
  
  .skills .img2 {
    display: block;
    margin: auto;
    max-width: 30%;
    height: 40%;
    position: absolute;
    right: 38.5%;
    transform: translateY(-100%);
    opacity: 0.3;
    z-index: -1;
  }
  