.About {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  
  .About .Left {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 1.5%;
    max-width: 48%;
  
  }
  
  .About .Left h1 {
    padding: 1%;
    margin-bottom: 1%;
    margin-top: auto;
  }
  
  .About .Left .who .btn {
    margin-top: 3%;
  }
  
  .Left .img {
    max-width: 10%;
    height: auto;
    z-index: 6;
    padding-top: 2%;
  }
  
  .About .Right {
    max-width: 48%;
    flex: 1;
  }
  
  .Right {
    margin: auto;
    color: aliceblue;
    vertical-align: top;
    align-items: center;
    justify-content: center;
  }
  
  .Right .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .Right .me {
    max-width: 65%;
  }
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .About {
      flex-direction: column;
    }
  
    .About .Left,
    .About .Right {
      max-width: 100%;
      margin: 2% auto;
    }
  
    .Left .img {
      max-width: 15%;
      padding-top: 5%;
    }
  
    .About .Right {
      margin-top: 3%;
    }
  }
  