.project-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;
}

.project-title{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.project-title img{
    align-self: center;
    max-width: 100%;
    max-height: 100px;
}

.project-title h2{
    color: white;
    padding: 1rem 0;
}


.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    padding-bottom: 2rem;
}

.project-card{
    background-color: #1a1919;
    align-items: center;
    padding: 1.2rem 1rem;
    margin-top: 20px;
}


.pro-btns{
    text-align: center;
    display: flex;
    padding: 0.5rem 0;
}

.pro-details p{
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: left;
}

@media screen and (max-width:740px){
    .project-container{
        max-width: 90%;
        margin:auto;
        grid-template-columns: 1fr;
    }

}