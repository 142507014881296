
.view-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
  text-align: center; /* Added */
  padding: 0 250px; /* Added */
}

  
  .view-project img {
    max-width: auto;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .view-project h2 {
    font-size: 2rem;
    color: aliceblue;
    margin-bottom: 20px;
  }
  
  .view-project p {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 30px; /* Increased */
    margin-top: 30px; /* Increased */
    color: whitesmoke;
    line-height: 1.5; /* Added */
    text-align: center; /* Added */
  }
  
  .view-project  a {
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
.back-button {
    background-color: #1c1c1c;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  .back-button:hover {
    background-color: rgb(41, 41, 41);
  }