* {
  margin: 0;
  font-family: Montserrat,sans-serif;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h4,p, a{
  color: aliceblue;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: pink;
  color: black;
  border: 1px solid whitesmoke;
  font-weight:600;
  cursor:pointer;
}

.btn-light {
  background: transparent;
  color: whitesmoke;
}

.btn:hover{
  background: rgba(255, 255, 255, 0.2);
  color:whitesmoke;
  transition: 0.3s;
}
