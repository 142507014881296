html, body {
  width: 100%;
  height:100%;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.mask{
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    width: 100%;
    height: 100vh;
    position: relative;
}

.Intro{
    height: 100%;
    width: 100%;
}
.Intro .content{
    position: absolute;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    text-align: center;
}

.Intro .content h1{
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.Intro .content p{
    font-size: 1.4rem;
    font-weight: 200;
    text-transform:uppercase;
}



