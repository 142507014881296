.Background-img{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 70vh;
    position: relative;
}

.Background-img::before{
    content: "";
    background-image: url('../images/BackImg.jpg');
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
}
.Heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Background-img h1{
    font-size: 2.4rem;
    padding-bottom: 0.7rem;
}
.Background-img p{
    font-size: 1.4rem;
    text-align: center;
}